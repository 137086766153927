import React from 'react';
import Pagination from '@mui/material/Pagination';
import { DataGrid, GridColDef, GridCellParams, MuiEvent } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import CircularProgress from '@mui/material/CircularProgress';

import { fetchGet, reportError } from '../../services/GettingData';
import ICompoundPropertiesResponse from '../../schemas/Compound/ICompoundPropertiesResponse';
import IReportFeatureErrorModel from '../../schemas/IReportFeatureErrorModel';
import ReportConfirmationDialog from '../Dialog/ReportConfirmationDialog';
import Doi from '../Doi';

type ICompoundProps = { compoundId?: string, property: string };

const CompoundPropertyComponent: React.FC<ICompoundProps> = (props) => {
  const pageChangeHandle = (event: React.ChangeEvent<unknown>, value: number) => {
    getPropertiesOfCompound(value);
  }

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'id',
      width: 0,
      hide: true
    },
    {
      field: 'urn',
      headerName: 'Reference',
      width: 250,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return Doi.DoiShort(params.value)
      },
    },
    {
      field: 'data',
      headerName: 'Data',
      width: 400,
      flex: 0.7,
      disableColumnMenu: true,
      editable: false,
      sortable: false,
    },
    {
      field: 'overall_quality',
      headerName: 'Overall quality',
      width: 100,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'report',
      headerName: '',
      width: 150,
      disableColumnMenu: true,
      editable: false,
      align: "center",
      renderCell: (params) => {
        return (
          <Button
            className='report-error-spectrum'
            onClick={() => onReportClick(params)}
            variant='outlined'
            color='error'
            sx={{ color: '#505050', borderColor: '#505050' }}
          >Report Error</Button>
        );
      },
    },
  ];


  const onReportClick = async(params: any) => {
    setFeatureParams(params);
    setDialogOpened(true); 
  }


  const [compoundPropertiesResponse, setCompoundPropertiesResponse] = React.useState<ICompoundPropertiesResponse>({ items: [], total: 0, page: 1, size: 10 });
  const [isLoading, setLoading] = React.useState<boolean>(true);
  const [isDialogOpened, setDialogOpened] = React.useState<boolean>(false);
  const [featureParams, setFeatureParams] = React.useState<{}>({});


  const getPropertiesOfCompound = (page: number) => {
    const fetchData = async () => {
      setLoading(true);
      const url = '/compound/id/' + props.compoundId + '/properties?property=' + props.property + '&page=' + page + '&size=' + compoundPropertiesResponse.size;
      const response = await fetchGet(url);
      const json = await response.json();
      setCompoundPropertiesResponse(json);
      setLoading(false);
    }
    fetchData()
      .catch(console.error);
  }


  const onCloseDialog = async (confirmed: boolean, mistakeComment: string) => {
    setDialogOpened(false);
    let params = featureParams as any;
    if (confirmed && params.row) {
      const featureError: IReportFeatureErrorModel = { publication_id: params.row.publication_id, 
                                                       compound_id: props.compoundId ? props.compoundId : '',
                                                       compound_property: props.property, 
                                                       value: params.row.data,
                                                       comment: mistakeComment }
      await reportError(JSON.stringify([featureError]));
    }
    setFeatureParams({});
  }


  React.useEffect(() => {
    getPropertiesOfCompound(compoundPropertiesResponse.page);
  }, [props.compoundId]);


  const rows = compoundPropertiesResponse.items.map((value, index) => {
    return {
      'urn': value.publication.urn,
      'data': (value as any)[props.property],
      'id': index,
      'overall_quality': value.overall_quality,
      'publication_id': value.publication_id
    }
  });


  return (<div>
    <ReportConfirmationDialog isDialogOpened={isDialogOpened} onClose={onCloseDialog}/>
    {isLoading ? <Grid xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
      <CircularProgress size={50} />
      
      </Grid> :
      <DataGrid
        autoHeight
        getRowHeight={() => 'auto'}
        rows={rows}
        columns={columns}
        onCellClick={(params: GridCellParams, event: MuiEvent<React.MouseEvent>) => {
          event.defaultMuiPrevented = true;
          //if (params.field === 'urn') Doi.gotoDoi(params.row.doi);
        }}
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
    }
    <div>
      <Pagination style={{ marginTop: '1em' }} count={Math.ceil(compoundPropertiesResponse.total / compoundPropertiesResponse.size)}
        page={compoundPropertiesResponse.page} onChange={pageChangeHandle} />
    </div>
  </div>)
};
export default CompoundPropertyComponent;
