import { io, Socket } from "socket.io-client";
import { getSession } from "../../services/Login";
import { refreshToken } from "../../services/Login";

class WebSocketConnection {
  private static instance: WebSocketConnection;
  private static jwt: string;
  
  private socket: Socket;

  private constructor() {
    this.initializeSocket();
  }

  public static getInstance(forceNew: boolean = false): WebSocketConnection {
    if (!WebSocketConnection.instance || forceNew) {
      WebSocketConnection.instance = new WebSocketConnection();
    }
    return WebSocketConnection.instance;
  }

  public reconnectWithNewToken() {
    if (this.socket) {
      this.socket.disconnect();
    }
    this.initializeSocket();
  }

  private initializeSocket() {
    console.log("Initializing socket");
    const url = "https://dev.odanchem.org/";
    const path = "/api/v2/socket.io";
    const session = getSession();

    if (this.socket) {
      this.socket.disconnect();
    }

    this.socket = io(url, {
      extraHeaders: {
        "Access-Control-Allow-Origin": "*",
      },
      transports: ["websocket", "polling", "flashsocket"],
      auth: {
        'mode': session === undefined ? 'anonymous' : 'jwt',
        'jwt': session?.access_token
      },
      path,
    });

    this.socket.on("connect_error", async (err) => {
      console.log("connect error", err);
   
      if (err.message === "Signature has expired") {
        console.log("jwt expired");
        try {
          const session = getSession();
          if (session?.refresh_token) {
            await refreshToken(session.refresh_token);
            console.log("jwt refreshed");
            this.reconnectWithNewToken();
          }
        } catch (error) {
          console.error("Failed to refresh token:", error);
        }
      }
    });

    this.socket.on("connect", () => {
      console.log("socket connected", this.socket.connected);
      this.initializeListeners();
    });

    this.socket.on("disconnect", () => {
      console.log("socket disconnected");
    });

    this.socket.on("reconnect_attempt", () => {
      console.log("socket reconnect_attempt");
    });

    this.socket.on('message', (data) => {
      console.error('Message ' + data);
    });
      
    this.socket.on("reconnect", () => {
      console.log("socket reconnected");
    });
  }

  public async refreshToken(): Promise<void> {
    const session = getSession();
    console.log("old session asscess token", session.access_token);
    await refreshToken(session?.refresh_token, session.access_token);
  }

  public getSocket(): Socket {
    return this.socket;
  }

  private initializeListeners(): void {
    this.socket.on("connect", () => {
      console.log("connection established");
    });

    this.socket.on("disconnect", () => {
      console.log("Disconnected from server");
    });
  }

  public clearSearches(): void {
    console.log(this.socket.emit("/debug/clear"));
  }
}

export default WebSocketConnection;