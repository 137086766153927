import React from 'react';
import { Switch, Redirect } from "react-router-dom";
import { BrowserRouter as Router, Route } from 'react-router-dom';
import ThemeProvider from '@mui/material/styles/ThemeProvider';

import MainPage from './pages/MainPage';
import HelpPage from './pages/HelpPage';
import LoginPage from './pages/Login/LoginPage';
import RestorePage from './pages/Login/RestorePage';
import RegisterPage from './pages/Login/RegisterPage';
import ExactSearchPage from './pages/Compound/ExactSearchPage';
import PrivacyPolicyPage from './pages/Login/PrivacyPolicyPage';
import SpectrumPage from './pages/Spectrum/SpectrumPage';

import ConfirmationPage from './pages/Login/ConfirmationPage';
import PersonalInfoPage from './pages/Login/PersonalInfoPage';
import HistoryPage from './pages/History/HistoryPage';
import CompoundHistoryPage from './pages/History/CompoundHistoryPage';
import SpectrumComparePage from './pages/Spectrum/SpectrumComparePage';
import RestoreConfirmationPage from './pages/Login/RestoreConfirmationPage';
import PublicationHistoryPage from './pages/History/PublicationHistoryPage';
import SubSearchPage from './pages/Compound/SubSearchPage';
import CompoundSearchSocketPage from './pages/Compound/CompoundSearchPage';
import SpectrumSearchSocketPage from './pages/Spectrum/SpectrumSearchPage';
import SpectrumSearchResultSocketPage from './pages/Spectrum/SpectrumSearchResultPage';
import PublicationSearchSocketPage from './pages/Publication/PublicationSearchPage';








import theme from "./theme";

function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <Switch>

          <Route path="/main" >
            <MainPage />
          </Route>

          <Route path="/help" >
            <HelpPage />
          </Route>

          <Route path="/exact-search/:molid" >
            {(props: any) => <ExactSearchPage {...props} />}
          </Route>

          <Route path="/exact-search/" >
            {(props: any) => <ExactSearchPage {...props} />}
          </Route>

          {<Route path="/publication-search" >
            {(props: any) => <PublicationSearchSocketPage {...props} />}
          </Route>}

          <Route path="/spectrum/:spectrid" >
            {(props: any) => <SpectrumPage {...props} />}
          </Route>

          <Route path="/spectrum-compare/:spectrid" >
            {(props: any) => <SpectrumComparePage {...props} />}
          </Route>

          <Route exact path="/"
            render={() => { return (<Redirect to="/main" />) }}
          />

          <Route exact path="/register"
            render={(props: any) => { return (<RegisterPage {...props} />) }}
          />

          <Route exact path="/login"
            render={(props: any) => { return (<LoginPage {...props} />) }}
          />

          <Route exact path="/confirmation"
            render={(props: any) => { return (<ConfirmationPage {...props} />) }}
          />

         <Route exact path="/restore-confirmation"
            render={(props: any) => { return (<RestoreConfirmationPage {...props} />) }}
          />

          <Route exact path="/restore"
            render={(props: any) => { return (<RestorePage {...props} />) }}
          />

         <Route exact path="/personal"
            render={(props: any) => { return (<PersonalInfoPage {...props} />) }}
          />

          <Route exact path="/search-history"
            render={(props: any) => { return (<HistoryPage {...props} />) }}
          />

         <Route exact path="/publication-history"
            render={(props: any) => { return (<PublicationHistoryPage {...props} />) }}
          />

          <Route exact path="/compound-history"
            render={(props: any) => { return (<CompoundHistoryPage {...props} />) }}
          />

         <Route exact path="/privacy-policy"
            render={(props: any) => { return (<PrivacyPolicyPage {...props} />) }}
          />


          <Route path="/compound-search" >
            {(props: any) => <CompoundSearchSocketPage {...props} />}
          </Route>
          <Route exact path="/sub-search/:page"
            render={(props: any) => { return (<SubSearchPage {...props} />) }}
          />

          <Route path="/spectrum-search-result/:searchid" >
            {(props: any) => <SpectrumSearchResultSocketPage {...props} />}
          </Route>

          <Route path="/spectrum-search-result" >
            {(props: any) => <SpectrumSearchResultSocketPage {...props} />}
          </Route>
    
         <Route path="/spectrum-search" >
            {(props: any) => <SpectrumSearchSocketPage {...props} />}
          </Route>

        </Switch>
      </ThemeProvider>

    </Router>
  );
}

export default App;
