import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import { useHistory } from "react-router-dom";
import Grid from '@mui/material/Unstable_Grid2';
import CircularProgress from '@mui/material/CircularProgress';

import { fetchGet, reportError } from '../../services/GettingData';
import IReportSpectraErrorModel from '../../schemas/IReportSpectraErrorModel';
import ICompoundSpectraResponse from '../../schemas/Compound/ICompoundSpectraResponse';
import ReportConfirmationDialog from '../Dialog/ReportConfirmationDialog';
import Doi from '../Doi';
import '../../App.css';

type ICompoundProps = {
  compoundId?: string,
  spectrType: string,
};

const CompoundSpectraComponent: React.FC<ICompoundProps> = (props) => {

  let history = useHistory();
  const columns: GridColDef[] = [
    {
      field: 'urn',
      headerName: 'Reference',
      width: 250,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return Doi.DoiShort(params.value)
      },
    },
    {
      field: 'spectrum_string',
      headerName: 'Spectrum string',
      width: 400,
      flex: 0.7,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      cellClassName: 'spectrum_string',
    },
    {
      field: 'quality',
      headerName: 'Quality',
      width: 100,
      editable: false,
      disableColumnMenu: true,
    },
    {
      field: 'overall_quality',
      headerName: 'Overall quality',
      sortable: false,
      width: 100,
      disableColumnMenu: true,
      editable: false,
    },
    {
      field: 'report',
      headerName: '',
      width: 150,
      editable: false,
      align: "center",
      renderCell: (params) => {
        return (
          <Button
            className='report-error-spectrum'
            onClick={(e) => onReportClick(e, params)}
            variant='outlined'
            sx={{ color: '#505050', borderColor: '#505050' }}
          >Report Error</Button>
        );
      },
    },
  ];

  const pageChangeHandle = (event: React.ChangeEvent<unknown>, value: number) => getSpectrum(value);


  const [compoundSpectrResponse, setCompoundSpectrResponse] = React.useState<ICompoundSpectraResponse>({ items: [], total: 0, page: 1, size: 10 });
  const [isLoading, setLoading] = React.useState<boolean>(true);
  const [isDialogOpened, setDialogOpened] = React.useState<boolean>(false);
  const [spectrParams, setSpectrParams] = React.useState<{}>({});

  
  const onReportClick = async(a: any, params: any) => {
    setSpectrParams(params);
    setDialogOpened(true); 
  }


  const getSpectrum = (page: number) => {
    const fetchData = async () => {
      setLoading(true);
      const url = '/spectrum/search?compound_id=' + props.compoundId + '&spectrum_type=' + props.spectrType + '&page=' + page + '&size=' + compoundSpectrResponse.size;
      const response = await fetchGet(url);
      const json = await response.json();
      setCompoundSpectrResponse(json);
      setLoading(false);
    }
    fetchData()
      .catch(console.error);
  }


  React.useEffect(() => {
    getSpectrum(compoundSpectrResponse.page);
  }, [props.compoundId]);


  const gotoSpectr = (spectrumId: string) => history.push('/spectrum/' + spectrumId);


  const onCloseDialog = async (confirmed: boolean, mistakeComment: string) => {
    setDialogOpened(false);
    let params = spectrParams as any;
    if (confirmed && params.row) {
      const spectrError: IReportSpectraErrorModel = { spectrum_id: params.row.id, 
                                                     spectrum_string: params.row.spectrum_string, 
                                                     comment: mistakeComment }
      const errors = [spectrError];
      await reportError(JSON.stringify(errors));
    }
    setSpectrParams({});
  }

  
  return (<div>
    <div>
      <div>
        <ReportConfirmationDialog isDialogOpened={isDialogOpened} onClose={onCloseDialog}/>
        <Box>
          { isLoading ? <Grid xs={12} style={{display:'flex', justifyContent:'center' }}>
            <CircularProgress size={50} />
            </Grid> :
          <DataGrid
            autoHeight
            getRowHeight={() => 'auto'}
            onCellDoubleClick={(params, event) => {
              if (!event.ctrlKey) {
                event.defaultMuiPrevented = true;
              }
              if (params.field === 'doi') Doi.gotoDoi(params.row.doi);
              if (params.field === 'spectrum_string') gotoSpectr(params.row.id);
       
            }}
            rows={compoundSpectrResponse.items}
            columns={columns}
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
          />}
        </Box>
      </div>
    </div>
    <Pagination style={{ marginTop: '1em' }} count={Math.ceil(compoundSpectrResponse.total / compoundSpectrResponse.size)} 
                                             page={compoundSpectrResponse.page} onChange={pageChangeHandle} />
  </div>)

};
export default CompoundSpectraComponent;
