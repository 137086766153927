import React, { useState, useEffect } from 'react';
import { Dialog, Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import Pagination from '@mui/material/Pagination';

import '../../App.css';
import AppSettings from '../../API/appSettings';
import Config from '../../config.json';
import { Item, Item2, Found } from '../../styles';
import translate from '../../services/Translation';
import { fetchPost } from '../../services/GettingData';
import Header from '../../components/HeaderComponent';
import MoleculeView from '../../components/MoleculeView';
import FilterComponent from '../../components/FilterComponent';
import ICombineQueryPayload from '../../schemas/History/ICombineQueryPayload';
import InternalError from '../../schemas/Exception/InternalError';
import Accept from '../../schemas/Compound/Accept';
import ProgressControl from '../../components/ProgressControl';
import WebSocketConnection from '../Sockets/WebSocketConnection';
import { SearchStatus } from '../../schemas/Compound/SearchStatus';
import HelpIconWithHint from '../../components/HelpIconWithHint';
import ISubSearchFilterModel from '../../schemas/Compound/ISubSearchFilterModel';
import { saveSubSearchFilterSettings } from '../../services/HandlingState';
import TimeoutError from '../../schemas/Exception/TimeoutError';
import IFinishInfo from '../../schemas/Compound/IFinishInfo';
import RefreshError from '../../schemas/Exception/RefreshError';
import IGetResponse from '../../schemas/Compound/IGetResponse';
import NotAuthorizedError from '../../schemas/Exception/NotAuthorizedError';
import ExpiredAccountError from '../../schemas/Exception/ExpiredAccountError';
import IHistoryPreviewModel from '../../schemas/History/IHistoryPreviewModel';
import { ICompoundSearchResponse } from '../../schemas/Compound/ICompoundSearchResponse';
import MoleculeEditor from '../Compound/MoleculeEditor';




type ICompoundHistoryWrapperProp = {
  location: {
    state: {
      historyPreview: IHistoryPreviewModel
    }
  }
};


export const CompoundHistoryPage: React.FC<ICompoundHistoryWrapperProp> = (props) => {

  const loadFilterSettings = (): ISubSearchFilterModel => {
    let filters = {
      mol_query: '',
      compound_props: JSON.parse(localStorage.getItem('compound_props') || '[]'),
      show_radicals: false,
      iterate_tautomers: false,
      optional_mols: [],//A14 getHistoryMolecules(),
      nmr_types: JSON.parse(localStorage.getItem('nmr_types') || '[]'),
      optional_svgs: []//A14 getHistorySVGs(),
    }
    return filters;
  }

  const [isLoading, setLoading] = useState<boolean>(true);
  const [subSearchFilterModel, setSubSearchFilterModel] = React.useState<ISubSearchFilterModel>(loadFilterSettings());
  const [isErrorDialogOpened, setErrorDialogOpened] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [socketConnection, setSocketConnection] = React.useState(WebSocketConnection.getInstance());
  const [search, setSearch] = React.useState<string>();
  const [searchStatus, setSearchStatus] = React.useState<SearchStatus>();
  //const [molecule, setMolecule] = React.useState<string>('');
  const [isApplyEnabled, setApplyEnabled] = React.useState<boolean>(false);
  //const [moleculeSVG, setMoleculeSVG] = React.useState<string>('');
  const [compoundSearchResponse, setCompoundSearchResponse] = React.useState<ICompoundSearchResponse>({
    items: [],
    total: 0,
    search_id: ''
  });

  
  const onFinish = (packet: IFinishInfo, searchId: string) => {
    console.log('onFinish', packet, searchId);
    if (packet.id === searchId) {
      console.log('/search/finish our obtained ');
      setSearchStatus(SearchStatus.Finished);
    }
  }


  const onGet = (data: ICompoundSearchResponse) => {
    if (search !== data.search_id) {
      console.log('onGet - another search id');
      return;
    }
    if (data.items.length === 0) {
      showError('Compound not found');
    }
    console.log('onGet compound', data);
    setCompoundSearchResponse(data);
    setLoading(false);
  }


  const onError = (packet: any) => {
    console.log('/error', packet.error);
    showError(packet.error);
    setLoading(false);
    //setSearch(undefined);
    //setSearchId(null);
  }


  const get = (_page: number = 1) => {
    if (search && socketConnection) {
      setLoading(true);
      const connection = socketConnection.getSocket();
      console.log('get search with a page', search, _page);
      connection.emit("/search/get", {
        "id": search,
        "page": _page,
        'per_page': Config.itemsPerPage,
      });
    }
  }


  React.useEffect(() => {
    console.log('useEffect socketConnection');
    if (socketConnection) {
      const connection = socketConnection.getSocket();
      console.log('subscription has started');
      
      connection.off('/error');
      connection.off('/search/get');
      connection.off('/search/accepted');
      connection.off('/search/finish');
      
      connection.on('/error', (data) => onError(data));
      connection.on('/search/accepted', (packet) => onAccept(packet, connection));
      
      return () => {
        // Cleanup all subscriptions on unmount
        connection.off('/error', onError);
        connection.off('/search/get', onGet);
        connection.off('/search/accepted', onAccept);
        connection.off('/search/finish', onFinish);
      };
    }
    else console.log('subscription hasnt started, it is closed ');
  }, [socketConnection])





  // useEffect(() => {
  //   setSubSearchFilterModel(prev => ({ ...prev, 
  //                                     from_search_id: (prev.from_search_id ? prev.from_search_id : compoundSearchResponse.search_id) }));
  //   console.log('useeffect on modification im compoundSearchResponse, .search_id=', compoundSearchResponse.search_id);
  // }, [compoundSearchResponse]);


  React.useEffect(() => {
    if (searchStatus === SearchStatus.Finished) {
      get();
    }
  }, [searchStatus]);


  const combine = async () => {
    if (socketConnection) {
      setSearchStatus(SearchStatus.Started);
      const combinePayload: ICombineQueryPayload = { 
        ids: props.location.state.historyPreview.queries_ids, 
        method: props.location.state.historyPreview.method 
      };
      socketConnection.getSocket().emit("/search/start", { "type": "combine_compound", "params": combinePayload });
      setApplyEnabled(false);
    }
    return true;
  };


    // useEffect(() => {
    //   if (subSearchFilterModel.from_search_id)
    //     refreshSub(page);
    //   else
    //     initialCombine();
    // }, [page]);

    useEffect(() => {
        combine();
        //setApplyEnabled(false);
      
    }, []);


  const pageChangeHandle = async (event: React.ChangeEvent<unknown>, page: number) => {
    get(page);
  }


  const filterChanged = (filterModel: ISubSearchFilterModel) => {
    console.log('filterChanged', filterModel);
    setSubSearchFilterModel(prev =>
    ({
      ...filterModel,
      mol_query: (subSearchFilterModel?.mol_query as string),
      optional_mols: (subSearchFilterModel?.optional_mols as string[]),
      optional_svgs: (subSearchFilterModel?.optional_svgs as string[]),
    }
    ));
    if (!isLoading) 
      setApplyEnabled(true);
  }


  const showError = (errorMessage: string) => {
    setErrorMessage(errorMessage);
    setErrorDialogOpened(true);
    setLoading(true);
  }


  // const searchAsSub = async (page: number): Promise<ICompoundSearchResponse> => {
  //   const endPoint = '/compound/search/sub?page=' + page + '&size=' + compoundSearchResponse?.size;
  //   try {
  //     const response = await fetchPost(endPoint, JSON.stringify(subSearchFilterModel), true, true);
  //     if (!response.ok) {
  //       showError(response.statusText);
  //       return { items: [], total: 0, page: 0, size: 0 }
  //     }
  //     return await response.json();
  //   }
  //   catch (e: any) {
  //     setLoading(false);
  //     if (e instanceof NotAuthorizedError)
  //       (props as any).history.push({ pathname: '/login', state: { backTo: endPoint, welcomeMessage: true } })
  //     if (e instanceof ExpiredAccountError)
  //       (props as any).history.push({ pathname: '/personal', state: { welcomeMessage: true, expired: true } });
  //     if (e instanceof RefreshError) {
  //       showError(e.message);
  //       (props as any).history.push({ pathname: '/login', state: { backTo: endPoint, welcomeMessage: true } })
  //     }
  //     if (e instanceof TimeoutError) {
  //       console.error(e.message);
  //       showError(e.message);
  //     }
  //     else
  //       showError(e.toString());
  //     return { items: [], total: 0, page: 0, size: 0 }
  //   }
  // }


  // const refreshSub = async (page: number = 1) => {
  //   setCompoundSearchResponse(await searchAsSub(page));
  //   setLoading(false);
  // }


  const onAccept = (packet: Accept, connection: any) => {
    console.log('/search/accepted happens', packet.id);

    connection.off('/search/get');
    connection.off('/search/accepted');
    connection.off('/search/finish');

    connection.on('/search/get', (packet) => onGet(packet));
    connection.on('/search/finish', (packet) => onFinish(packet, packet.id));
    setSearch(packet.id);
  }


  const applyFilter = async (): Promise<void> => {
    //setLoading(true);
    //setApplyEnabled(false);
    //await refreshSub();
  }

  const onUpdateMolecule = (_molecule: string, _moleculeSVG) => {
    setSubSearchFilterModel({ ...subSearchFilterModel, 
                              optional_mols: [_molecule], 
                              optional_svgs: [_moleculeSVG] });
//    setMolecule(molecule);
//    setMoleculeSVG(moleculeSVG);
    setApplyEnabled(true);
  }


  return (
    <Grid container spacing={0} className='main-frame'>
      <ProgressControl isLoading={isLoading} />
      <Grid md={12}>
        <Item2><div style={{ height: '2em' }}></div></Item2>
      </Grid>
      <Header title={props.location.state.historyPreview.method === 'union' ? 'Search Union' : 'Search Intersection'} showLogin={true} />

      <Dialog open={isModalOpen} onClose={() => setModalOpen(false)} maxWidth="lg" fullWidth>
        <MoleculeEditor updateMolecule={onUpdateMolecule}
          editorLoaded={() => { setLoading(false); }}
          closeWindow={() => setModalOpen(false)}
          previousMolecule={subSearchFilterModel.optional_mols[0]} />
      </Dialog>


      <Grid container style={{ minWidth: "250px", marginTop: '4em' }} md={12} justifyContent="center">
        <Grid style={{ minWidth: "250px", marginLeft: '1em', marginRight: '1em', }}>
          <div>Extra filter</div>
          <Item style={{ width: "250px", marginTop: '12px' }} 
                onClick={() => { saveSubSearchFilterSettings(subSearchFilterModel) }} >
          <div onClick={() => {
                setLoading(true);
                setModalOpen(true)
              }}>
            <MoleculeView 
              historyPreview={props.location.state.historyPreview}
              svgContent={subSearchFilterModel.optional_svgs[0] || ''}
              tooltip={'Open ketcher to draw substructure to filter results'}
              isMoleculeInContainer={!!subSearchFilterModel.optional_svgs[0]} />
              </div>
          </Item>
        </Grid>

        <Grid>
          <div style={{ marginBottom: '12px' }}><span className='box-title'>Filter</span></div>
          {<FilterComponent onChanged={filterChanged} subSearchFilterModel={subSearchFilterModel} />}
        </Grid>
      </Grid>
      <Grid md={12} justifyContent="center">
        <div style={{ marginBottom: '1em', marginTop: '2em', textAlign: 'center' }}>
            <Button disabled={!isApplyEnabled} 
                    title='Apply selected filters to your results'
                    onClick={combine} 
                    variant="contained">Apply Filter
            </Button>
          <HelpIconWithHint title={''}
            text={'Apply selected filters to the current search results'}
            handleOpen={() => { }} />
        </div>

      </Grid>

      <Grid container md={12}>
        <Grid md={5} style={{ marginTop: '4em', padding: '1em' }}></Grid>
        <Grid md={2} style={{ marginTop: '4em', padding: '1em' }}></Grid>
        <Grid md={5} style={{ marginTop: '4em', padding: '1em' }}></Grid>
      </Grid>

      <Grid container md={12}>
        <Grid md={1}></Grid>
        <Grid md={5} sm={12} style={Found}>
          Found {compoundSearchResponse?.total} compounds
        </Grid>
        <Grid md={5}>
        </Grid>
        <Grid md={1}></Grid>
      </Grid>

      <Grid container md={12}>
        <Grid md={1}></Grid>
        <Grid container md={10} sm={12}>
          {
            compoundSearchResponse?.items &&
            compoundSearchResponse.items.map((compound, index) => (
              <Grid container md={12} sm={12}
                style={{ paddingTop: '0.7em' }}
                key={index} className='compound-feed'>

                <Grid md={3} sm={12}>
                  <MoleculeView link={''} moleculeId={compound.id}
                    svgContent={compound.svg}
                    isMoleculeInContainer={true}
                    inline={true}
                    moleculeString={compound.molecule}
                  />
                </Grid>
                <Grid container md={9} sm={12} xs={12}>
                  <Grid md={4} sm={4} xs={12}>
                    {compound.total_publications ? <div>Publications found: {compound.total_publications}</div> : null}
                  </Grid>
                  <Grid md={4} sm={4} xs={12}>
                    {
                      Object.entries(compound.properties).map(([key, value]) => {
                        return (value > 0 && <div key={key}>{translate(key)} found: {value}</div>)
                      })
                    }
                  </Grid>
                  <Grid md={4} sm={4} xs={12}>
                    {compound.associated_spectra && Object.entries(compound.associated_spectra).map((spectra, count) => (
                      <div key={spectra[0]}>{translate(spectra[0])} spectra found: {spectra[1]}</div>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            ))
          }
        </Grid>
        <Grid md={1}></Grid>
      </Grid>

      <Grid md={12} sm={12} className='pagination-line' style={{ display: "inline" }} >
        <Grid style={{ width: '100%' }}>
          {compoundSearchResponse && compoundSearchResponse?.total > 0 &&
            <Pagination count={Math.ceil(compoundSearchResponse?.total / compoundSearchResponse?.pagination?.per_page)}
              page={compoundSearchResponse?.pagination?.page} onChange={pageChangeHandle} />}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CompoundHistoryPage;