import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Unstable_Grid2'
import AutoStoriesRoundedIcon from '@mui/icons-material/AutoStoriesRounded';
import Pagination from '@mui/material/Pagination';
import { Tooltip } from '@mui/material';

import { Item2 } from '../../styles';
import Header from '../../components/HeaderComponent';
import ProgressControl from '../../components/ProgressControl';
import ICombineQuery from '../../schemas/History/ICombineQuery';
import { fetchPost } from '../../services/GettingData';
import { ICombineResponse } from '../../schemas/History/ICombineResponse';
import { transformJsonToAcs } from '../../services/Publication'; 
import Config from '../../config.json';
import translate from '../../services/Translation';
import MoleculeView from '../../components/MoleculeView';
import FilterComponent from '../../components/FilterComponent';
import ErrorDialog from '../../components/ErrorDialog';

import ICombineQueryPayload from '../../schemas/History/ICombineQueryPayload';
import InternalError from '../../schemas/Exception/InternalError';
import Accept from '../../schemas/Compound/Accept';
import WebSocketConnection from '../Sockets/WebSocketConnection';
import { SearchStatus } from '../../schemas/Compound/SearchStatus';
import HelpIconWithHint from '../../components/HelpIconWithHint';
import ISubSearchFilterModel from '../../schemas/Compound/ISubSearchFilterModel';
import { saveSubSearchFilterSettings } from '../../services/HandlingState';
import TimeoutError from '../../schemas/Exception/TimeoutError';
import IFinishInfo from '../../schemas/Compound/IFinishInfo';
import RefreshError from '../../schemas/Exception/RefreshError';
import IGetResponse from '../../schemas/Compound/IGetResponse';
import NotAuthorizedError from '../../schemas/Exception/NotAuthorizedError';
import ExpiredAccountError from '../../schemas/Exception/ExpiredAccountError';
import IHistoryPreviewModel from '../../schemas/History/IHistoryPreviewModel';
//import { ICompoundSearchResponse } from '../../schemas/Compound/ICompoundSearchResponse';
import { IPublicationSearchResponse } from '../../schemas/Publication/IPublicationSearchResponse';



type HistoryOperationProp = {
  location: {
    state: ICombineQuery
  }
};

export const PublicationHistoryPage: React.FC<HistoryOperationProp> = (props) => {
  const [combineResponse, setCombineResponse] = useState<IPublicationSearchResponse>();
  const [combineQuery, setCombineQuery] = useState<ICombineQuery>({ ...props.location.state, page: 1, size: 10 });
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isErrorDialogOpened, setErrorDialogOpened] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [search, setSearch] = React.useState<string>();
  const [searchStatus, setSearchStatus] = React.useState<SearchStatus>();
  const [socketConnection, setSocketConnection] = React.useState(WebSocketConnection.getInstance());
  const [page, setPage] = useState<number>(1);
 

  const theme = useTheme();
  const isMdOrLarger = useMediaQuery(theme.breakpoints.up('md'));


  const pageChangeHandle = (event: React.ChangeEvent<unknown>, value: number) => {
    setCombineQuery({ ...combineQuery, page: value });
  };


  useEffect(() => {
    combine();
  }, [combineQuery]);





  const onFinish = (packet: IFinishInfo, searchId: string) => {
    if (packet.id === searchId) {
      console.log('/search/finish our obtained ');
      setSearchStatus(SearchStatus.Finished);
    }
  }


  const onGet = (data: IPublicationSearchResponse) => {
    if (search !== data.search_id) {
      console.log('onGet - another search id');
      return;
    }
    console.log('onGet compound', data);
    if (data.items.length === 0) {
      showError('Compound not found');
    }
    setCombineResponse(data);
    setLoading(false);
  }


  const onError = (packet: any) => {
    console.log('/error', packet.error);
    showError(packet.error);
    setLoading(false);
    setSearch(undefined);
  }


  const get = (currentPage: number = 1) => {
    if (search && socketConnection) {
      const connection = socketConnection.getSocket();
      console.log('get search with a page', search, currentPage);
      connection.emit("/search/get", {
        "id": search,
        "page": currentPage,
        'per_page': Config.itemsPerPage,
      });
    }
  }


  React.useEffect(() => {
    console.log('useEffect socketConnection');
    if (socketConnection) {
      const connection = socketConnection.getSocket();
      console.log('subscription has started');
         
      connection.off('/search/get');
      connection.off('/search/accepted');
      connection.off('/search/finish');
   
      connection.on('/error', (data) => onError(data));
      connection.on('/search/get', (packet) => onGet(packet));
      connection.on('/search/accepted', (packet) => onAccept(packet, connection));
      return () => {
        connection.off('/search/accepted', onAccept);
        connection.off('/search/finish', onFinish);
      };
    }
    else console.log('subscription hasnt started, it is closed ');
  }, [socketConnection])


//   const combine = async (): Promise<boolean> => {
//     if (socketConnection) {
//       setSearchStatus(SearchStatus.Started);
//       //setCompoundSearch(undefined);
//       type combineSearchPayload = {
//    ids: [string];
//    method: "union" | "intersection";
// }



  // useEffect(() => {
  //   setSubSearchFilterModel(prev => ({ ...prev, 
  //                                     from_search_id: (prev.from_search_id ? prev.from_search_id : compoundSearchResponse.search_id) }));
  //   console.log('useeffect on modification im compoundSearchResponse, .search_id=', compoundSearchResponse.search_id);
  // }, [compoundSearchResponse]);


  React.useEffect(() => {
    if (searchStatus === SearchStatus.Finished) {
      get();
    }
  }, [searchStatus]);


  const combine = async () => {
    if (socketConnection) {
      setLoading(true);
      setSearchStatus(SearchStatus.Started);
      const combinePayload: ICombineQueryPayload = { 
        ids: props.location.state.queries_ids, 
        method: props.location.state.method 
      };
      socketConnection.getSocket().emit("/search/start", { "type": "combine_publication", "params": combinePayload });
    }
    return true;
  }


  // useEffect(() => {
  //     combine();
  // }, [page]);


  const showError = (errorMessage: string) => {
    setErrorMessage(errorMessage);
    setErrorDialogOpened(true);
    setLoading(true);
  }

  
  const closeErrorDialog = () => {
    setErrorDialogOpened(false);
  }


  const onAccept = (packet: Accept, connection: any) => {
    console.log('/search/accepted happens', packet.id);
    connection.on('/search/finish', (packet) => onFinish(packet, packet.id));
    setSearch(packet.id);
  }


  // const applyFilter = async (): Promise<void> => {
  //   setLoading(true);
  //   await refreshSub();
  // }









  return (
    <Grid container spacing={0} className='main-frame'>
      <ProgressControl isLoading={isLoading} />
      <Grid xs={12}>
        <Item2><div style={{ height: '2em' }}></div></Item2>
      </Grid>
      <ErrorDialog isDialogOpened={isErrorDialogOpened}
        errorMessage={errorMessage}
        warningMessage={''}
        onClose={closeErrorDialog} />

      <Header title={combineQuery.method === 'union' ? 'Search Union' : 'Search Intersection'} showLogin={true} />
      <Grid container xs={12}>
        <Grid md={5} style={{ marginTop: '4em', padding: '1em' }}></Grid>
        <Grid md={2} style={{ marginTop: '4em', padding: '1em' }}></Grid>
        <Grid md={5} style={{ marginTop: '4em', padding: '1em' }}></Grid>
      </Grid>

      <Grid container xs={12}>
        <Grid md={1}></Grid>
        <Grid md={5} style={{ fontSize: '1.2em', fontWeight: '600', marginBottom: '1em' }}>
          Found {combineResponse?.total} publications
        </Grid>
        <Grid md={5}>
        </Grid>
        <Grid md={1}></Grid>
      </Grid>
      {combineResponse?.items.map((elem, index) => (
        <Grid container xs={12} style={{ fontSize: '1.5em' }} key={index}>
          <Grid xs={0} md={1}></Grid>
          <Grid sm={12} md={5} style={{
            padding: '0.5em',
            fontWeight: '600',
            borderColor: 'lightgray',
            borderTopLeftRadius: index === 0 ? '3px' : '0px',
            borderBottomLeftRadius: '3px',
            borderCollapse: 'separate',
            borderWidth: index === 0 ? '1px' : '0px 1px 1px 1px',
            borderRightWidth: isMdOrLarger ? '0px' : '1px',
            borderStyle: 'solid'
          }}>
            <Grid xs={12} container>
              <Grid xs={8} style={{ textAlign: 'left' }}>
                {
                  <div style={{padding:'.5em'}} dangerouslySetInnerHTML={{__html:transformJsonToAcs({
                    id: elem.id,
                    title: elem.title,
                    authors: elem.authors,
                    journal: elem.journal,
                    year: elem.year,
                    volume: elem.volume,
                    pages: elem.pages,
                    urn: elem.urn,
                    url: ''
                  })}}>

                  </div>}
              </Grid>
              <Grid xs={4} style={{ textAlign: 'right', flexGrow: 1 }}>
                <a href={'/publication-search?publicationId=' + elem.id} target='_blank'>
                  <Tooltip title="See compounds and spectra described in the paper">
                    <AutoStoriesRoundedIcon style={{ fontSize: '1.5em' }} color="primary" />
                  </Tooltip>
                </a>
              </Grid>
            </Grid>
          </Grid>

          <Grid sm={12} md={5} style={{
            padding: '0.5em',
            fontWeight: '600',
            borderColor: 'lightgray',
            borderTopRightRadius: index === 0 ? '3px' : '0px',
            borderBottomRightRadius: '3px',
            borderCollapse: 'separate',
            borderWidth: index === 0 ? '1px' : '0px 1px 1px 1px',
            borderStyle: 'solid',
            fontSize: '1em'
          }}>
            {/* <div>Compounds found: {elem.n_compounds}</div>
            {elem.spectra_counts && Object.keys(elem.spectra_counts).map((key, i) => {
              return (<div key={i}>
                {key.replace('_', ' ')} spectra: {elem.spectra_counts[key]}
              </div>);
            })} */}
          </Grid>
          <Grid sm={0} md={1}></Grid>
        </Grid>
      ))}

      {!isLoading && combineResponse && combineResponse?.items.length > 0 &&
        <Grid xs={12} className='pagination-line' style={{ display: "inline" }} spacing={1}>
          <Pagination style={{ marginTop: '1em' }}
            count={Math.ceil((combineResponse ? combineResponse.total : 0) /
              (combineResponse ? combineResponse.pagination.per_page : 10))}
            page={combineResponse.pagination.page} onChange={(e, v) => pageChangeHandle(e, v)} />
        </Grid>}
    </Grid >
  );
}
export default PublicationHistoryPage;